<template lang="pug">
  v-container#inv(fluid='' tag='section' style="padding: 0px;")
    v-snackbar(:timeout='30000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
        v-icon mdi-close
    base-material-card.px-5.py-3(icon='mdi-certificate' title='Certificación Electrónica' style='min-height: 100vh;')
      v-container#billing-table(fluid='' tag='section')
        v-row
          v-col(cols='12' md='12' style='padding: 0px;')
            v-stepper(v-model='e1')
              v-stepper-header
                v-stepper-step(:complete='e1 > 1' step='1') Información Importante
                v-divider
                v-stepper-step(:complete='e1 > 2' step='2') Datos de la Empresa
                v-divider
                //v-stepper-step(:complete='e1 > 3' step='3') Creación de usuario y empresa
              v-stepper-items
                v-stepper-content(step='1')
                  v-card.mb-12(style='padding: 10px; margin:0;' flat)
                    v-card-text(style="font-weight: bold; font-size: 18px;")
                      h4 CERTIFICADO ELECTRÓNICO (IMPORTANTE)
                    v-row
                      v-col(cols="12")
                        v-alert(v-model="alertDataSend" dismissible dense='' border='left' type='info')
                          | Verifique la fecha de caducidad de su certificado electrónico.
                      v-col(cols="12" md="10")
                        p(style="textAlign: justify;") 1).- Puede solicitar asistencia llamando al Centro de Atención Telefónica 1700 774 774 o solicitar información y asistencia a los funcionarios del SRI a nivel nacional.
                        p(style="textAlign: justify;") 2).- Se detalla a continuación las direcciones electrónicas de las entidades en donde se puede encontrar detalles específicos de los certificados digitales de firma electrónica:
                        a(href="http://www.eci.bce.ec" style="padding-left: 50px;" target="_blank") Banco Central del Ecuador
                        br
                        a(href="https://www.funcionjudicial.gob.ec/" style="padding-left: 50px;" target="_blank") Consejo de la Judicatura
                        br
                        a(href="https://www.securitydata.net.ec/" style="padding-left: 50px;" target="_blank") Security Data
                        br
                        a(href="https://www.anf.es/" style="padding-left: 50px;" target="_blank") ANF AC Ecuador
                        br
                        br
                        p(style="textAlign: justify;") La solicitud de certificación para los ambientes de Pruebas y Producción deberá realizarla directamente a través del Portal WEB del SRI (Servicios en Línea), recuerde que debe encontrarse en estado activo, al día en sus obligaciones tributarias y haber registrado un convenio de débito para pago de declaraciones para obtener exitosamente la autorización, esta solicitud se realizará una sola vez para cada ambiente.
                      v-col(cols="12" md="2")
                        br
                        p(style="textAlign: justify; font-weight: bold; font-size: 14px;") Cualquier duda siempre podrá comunicarse de manera gratuita a nuestros canales de comunicación oficiales.
                        p(style="textAlign: justify; font-weight: bold; font-size: 14px;") Teléfono y Whatsapp: 0984530998
                        v-btn.ml-2(text='' style="width: 100%;" @click="openLink('https://wa.me/+593984530998')")
                          v-icon(color="black") mdi-whatsapp
                  v-btn(color='primary' @click='nextEventProcess' :loading="loadingSaveData")
                    | Siguiente
                v-stepper-content(step='2' v-if="selectAccount.code === '001'")
                  v-card.mb-12(style='padding: 10px' flat)
                    v-col(cols='12' md='12' v-if="profile ? profile.twoAccounts : false")
                      v-select(style="font-size: 26px; height: 30px; margin-bottom: 10px;" return-object v-model='selectAccount' :items="acoountsLst" label="Seleccione la cuenta" item-text='texto')
                    v-form(ref='formCompanyData' v-model='validCompanyData' lazy-validation='')
                      v-row
                        v-col(cols="12")
                          v-alert(v-model="alertTipoAmbiente" dismissible dense='' border='left' type='info ')
                            | {{msgTipoAmbiente}}
                            a(style='color:blue;' href="https://srienlinea.sri.gob.ec/sri-catastro-tributario-web-internet/pages/certificado/opciones-certificado.jsf?&contextoMPT=https://srienlinea.sri.gob.ec/tuportal-internet&pathMPT=RUC&actualMPT=Certificados%20&linkMPT=%2Fsri-catastro-tributario-web-internet%2Fpages%2Fcertificado%2Fopciones-certificado.jsf%3F&esFavorito=S" target='_blank')
                              strong(style="text-decoration: underline;") Consulta tu RUC.
                        v-col(cols="12" md='4')
                          v-row
                            v-col(cols="12")
                              v-switch(@change="changeRegimenRimpeEmprendedor" v-model='companydata.regimenRimpeEmprendedor' label="CONTRIBUYENTE RÉGIMEN RIMPE - EMPRENDEDOR" color="primary" style="text-align: center;")
                            v-col(cols="12")
                              v-switch(@change="changeRegimenNegocio" v-model='companydata.regimenRimpeNegocio' label="CONTRIBUYENTE RÉGIMEN RIMPE - NEGOCIO POPULAR" color="primary" style="text-align: center;")
                            v-col(cols="12")
                              v-switch(@change="changeRegimenGeneral" v-model='companydata.regimenGeneral' label="CONTRIBUYENTE RÉGIMEN GENERAL" color="primary" style="text-align: center;")
                            v-col(cols="12")
                              v-switch(@change="changeRegimenMicroEmpresa" v-model='companydata.regimenMicroempresas' label="CONTRIBUYENTE RÉGIMEN MICROEMPRESAS" color="primary" style="text-align: center;")
                        v-col(cols="12" md='8')
                          v-row
                            v-col(cols="12" md="6")
                              v-row
                                v-col(cols="12")
                                  v-text-field(v-model='companydata.contribuyenteEspecial' label="Contribuyente Especial - Nro. Resolución" type="text" maxlength="5")
                                v-col(cols="12")
                                  v-select(return-object v-model='companydata.agenteRetencion' :items="electronicbillingtables ? electronicbillingtables.agenteRetencion : []" label="Agente de Retención Nro. Resolución" style="padding-top: 26px;")
                                  //v-text-field(v-model='companydata.agenteRetencion' label="Agente de Retención Nro. Resolución" type="text" maxlength="200")
                                v-col(cols="12")
                                  v-text-field(v-model='companydata.calificacionArtesanal' @keyup="keyUpArtesanal()" label="Calificación Artesanal" type="text")
                                v-col(cols="12")
                                  v-switch(v-model='companydata.obligadoContabilidad' label="OBLIGADO A LLEVAR CONTABILIDAD" color="primary" style="text-align: center;")
                            v-col(cols="12" md="6")
                              v-row
                                v-col( v-if="!banViewCert" cols="12")
                                  v-row
                                    v-col(cols="10")
                                      v-text-field(:rules="requeridos" v-model='passCert' :append-icon="show_pass_cert ? 'mdi-eye' : 'mdi-eye-off'" :type="show_pass_cert ? 'text' : 'password'" name='pass_fact_repeat' label='Contraseña de su Certificado Digital' hint='Mínimo 9 letras' counter='' @click:append='show_pass_cert = !show_pass_cert' maxlength="30" @keyup="validCertData")
                                    v-col(cols="2")
                                      v-tooltip(bottom='')
                                        template(v-slot:activator='{ on, attrs }')
                                          v-btn(icon='' style='background: transparent;' color='blue' v-on='on').mt-4
                                            v-icon(style="font-size: 20px;" @click="banViewCert = true") mdi-window-close
                                        span.tooltips Cancelar
                                v-col( v-if="banViewCert" cols="12")
                                  v-row
                                    v-col(cols="10")
                                      v-text-field(:rules="requeridos" v-model='companydata.passCert' :append-icon="show_pass_cert ? 'mdi-eye' : 'mdi-eye-off'" :type="show_pass_cert ? 'text' : 'password'" name='pass_fact_repeat' label='Contraseña de su Certificado Digital' hint='Mínimo 9 letras' counter='' @click:append='show_pass_cert = !show_pass_cert' maxlength="30" @keyup="validCertData")
                                    v-col(cols="2")
                                      v-tooltip(bottom='')
                                        template(v-slot:activator='{ on, attrs }')
                                          v-btn(icon='' style='background: transparent;' color='blue' v-on='on').mt-4
                                            v-icon(style="font-size: 20px;" @click="banViewCert = false") mdi-reload
                                        span.tooltips Cambiar Certificado
                                v-col(cols="12" v-if="!banViewCert" style="padding-top: 26px;")
                                  v-file-input(:rules="requeridos" label="Seleccionar Certificado", v-model='cert', @change="subir_cert")
                                v-col(cols="12" v-if="banViewCert" style="padding-top: 26px;")
                                  v-text-field(:rules="requeridos" label="Certificado Actual", v-model='companydata.nameCert', @change="subir_cert")
                                v-col(cols="12" style="padding-top: 47px;")
                                  p Caducidad del certificado: {{companydata.certExpiration}}
                                v-col(cols="12" style="padding-top: 20px;")
                                  v-btn(:disabled="banViewCert" color='secondary' @click="createCertificate" :loading="loadingUploadCertificate" style="width: 100%;")
                                    | Subir Certificado
                        v-col(cols="12" md="8")
                          v-text-field(v-model='companydata.dirEstablecimiento' :rules="requeridos" label="Dirección del Establecimiento Emisor" type="text" maxlength="300" style="padding-top: 18px;")
                        v-col(cols="12" md="4" style="padding-top: 20px;")
                          v-select(disabled @change="changeAmbiente" :rules="requeridos" return-object v-model='companydata.ambiente' :items="electronicbillingtables ? electronicbillingtables.tipoambiente : []" label="Tipo de ambiente" item-text='texto')
                  v-btn(color='red' text='' @click='e1 = 1' :loading="loadingSaveData") Atras
                  v-btn(color='primary' @click='nextEventCompany' :loading="loadingSaveData")
                    | Finalizar
                v-stepper-content(step='2' v-if="selectAccount.code === '002'")
                  v-card.mb-12(style='padding: 10px' flat)
                    v-col(cols='12' md='12' v-if="profile.twoAccounts")
                      v-select(style="font-size: 26px; height: 30px; margin-bottom: 10px;" return-object v-model='selectAccount' :items="acoountsLst" label="Seleccione la cuenta" item-text='texto')
                    v-form(ref='formCompanyData2' v-model='validCompanyData2' lazy-validation='')
                      v-row
                        <!--v-col(cols="12")-->
                          <!--v-alert(v-model="alertTipoAmbiente" dismissible dense='' border='left' type='info ')-->
                            <!--| {{msgTipoAmbiente}}-->
                            <!--a(style='color:blue;' href="https://srienlinea.sri.gob.ec/sri-catastro-tributario-web-internet/pages/certificado/opciones-certificado.jsf?&contextoMPT=https://srienlinea.sri.gob.ec/tuportal-internet&pathMPT=RUC&actualMPT=Certificados%20&linkMPT=%2Fsri-catastro-tributario-web-internet%2Fpages%2Fcertificado%2Fopciones-certificado.jsf%3F&esFavorito=S" target='_blank')-->
                              <!--strong(style="text-decoration: underline;") Consulta tu RUC.-->
                        v-col(cols="12" md='4')
                          v-row
                            v-col(cols="12")
                              v-switch(@change="changeRegimenRimpeEmprendedor2" v-model='companydata2.regimenRimpeEmprendedor' label="CONTRIBUYENTE RÉGIMEN RIMPE - EMPRENDEDOR" color="primary" style="text-align: center;")
                            v-col(cols="12")
                              v-switch(@change="changeRegimenNegocio2" v-model='companydata2.regimenRimpeNegocio' label="CONTRIBUYENTE RÉGIMEN RIMPE - NEGOCIO POPULAR" color="primary" style="text-align: center;")
                            v-col(cols="12")
                              v-switch(@change="changeRegimenGeneral2" v-model='companydata2.regimenGeneral' label="CONTRIBUYENTE RÉGIMEN GENERAL" color="primary" style="text-align: center;")
                            v-col(cols="12")
                              v-switch(@change="changeRegimenMicroEmpresa2" v-model='companydata2.regimenMicroempresas' label="CONTRIBUYENTE RÉGIMEN MICROEMPRESAS" color="primary" style="text-align: center;")
                        v-col(cols="12" md='8')
                          v-row
                            v-col(cols="12" md="6")
                              v-row
                                v-col(cols="12")
                                  v-text-field(v-model='companydata2.contribuyenteEspecial' label="Contribuyente Especial - Nro. Resolución" type="text" maxlength="5")
                                v-col(cols="12")
                                  v-select(return-object v-model='companydata2.agenteRetencion' :items="electronicbillingtables ? electronicbillingtables.agenteRetencion : []" label="Agente de Retención Nro. Resolución" style="padding-top: 26px;")
                                  //v-text-field(v-model='companydata2.agenteRetencion' label="Agente de Retención Nro. Resolución" type="text" maxlength="200")
                                v-col(cols="12")
                                  v-text-field(v-model='companydata2.calificacionArtesanal' @keyup="keyUpArtesanal2()" label="Calificación Artesanal" type="text")
                                v-col(cols="12")
                                  v-switch(v-model='companydata2.obligadoContabilidad' label="OBLIGADO A LLEVAR CONTABILIDAD" color="primary" style="text-align: center;")
                            v-col(cols="12" md="6")
                              v-row
                                v-col( v-if="!banViewCert2" cols="12")
                                  v-row
                                    v-col(cols="10")
                                      v-text-field(:rules="requeridos" v-model='passCert2' :append-icon="show_pass_cert2 ? 'mdi-eye' : 'mdi-eye-off'" :type="show_pass_cert2 ? 'text' : 'password'" name='pass_fact_repeat' label='Contraseña de su Certificado Digital' hint='Mínimo 9 letras' counter='' @click:append='show_pass_cert2 = !show_pass_cert2' maxlength="30" @keyup="validCertData2")
                                    v-col(cols="2")
                                      v-tooltip(bottom='')
                                        template(v-slot:activator='{ on, attrs }')
                                          v-btn(icon='' style='background: transparent;' color='blue' v-on='on' :loading="loadingPrintFac").mt-4
                                            v-icon(style="font-size: 20px;" @click="banViewCert2 = true") mdi-window-close
                                        span.tooltips Cancelar
                                v-col( v-if="banViewCert2" cols="12")
                                  v-row
                                    v-col(cols="10")
                                      v-text-field(:rules="requeridos" v-model='companydata2.passCert' :append-icon="show_pass_cert2 ? 'mdi-eye' : 'mdi-eye-off'" :type="show_pass_cert2 ? 'text' : 'password'" name='pass_fact_repeat2' label='Contraseña de su Certificado Digital' hint='Mínimo 9 letras' counter='' @click:append='show_pass_cert2 = !show_pass_cert2' maxlength="30" @keyup="validCertData2")
                                    v-col(cols="2")
                                      v-tooltip(bottom='')
                                        template(v-slot:activator='{ on, attrs }')
                                          v-btn(icon='' style='background: transparent;' color='blue' v-on='on').mt-4
                                            v-icon(style="font-size: 20px;" @click="banViewCert2 = false") mdi-reload
                                        span.tooltips Cambiar Certificado
                                v-col(cols="12" v-if="!banViewCert2" style="padding-top: 26px;")
                                  v-file-input(:rules="requeridos" label="Seleccionar Certificado", v-model='cert2', @change="subir_cert2")
                                v-col(cols="12" v-if="banViewCert2" style="padding-top: 26px;")
                                  v-text-field(:rules="requeridos" label="Certificado Actual", v-model='companydata2.nameCert', @change="subir_cert2")
                                v-col(cols="12" style="padding-top: 47px;")
                                  p Caducidad del certificado: {{companydata2.certExpiration}}
                                v-col(cols="12")
                                  v-btn(:disabled="banViewCert2" color='secondary' @click="createCertificate2" :loading="loadingUploadCertificate2" style="width: 100%;")
                                    | Subir Certificado
                        v-col(cols="12" md="8")
                          v-text-field(v-model='companydata2.dirEstablecimiento' :rules="requeridos" label="Dirección del Establecimiento Emisor" type="text" maxlength="300" style="padding-top: 18px;")
                        v-col(cols="12" md="4" style="padding-top: 18px;")
                          v-select(disabled @change="changeAmbiente2" :rules="requeridos" return-object v-model='companydata2.ambiente' :items="electronicbillingtables ? electronicbillingtables.tipoambiente : []" label="Tipo de ambiente" item-text='texto')
                  v-btn(color='red' text='' @click='e1 = 1' :loading="loadingSaveData") Atras
                  v-btn(color='primary' @click='nextEventCompany2' :loading="loadingSaveData")
                    | Finalizar
</template>
<script>
  import moment from 'moment'
  var forge = require('node-forge')
  export default {
    data: () => ({
      acoountsLst: [
        {
          code: '001',
          texto: 'Ingresar datos de la Cuenta 1.',
        },
        {
          code: '002',
          texto: 'Ingresar datos de la Cuenta 2.',
        },
      ],
      selectAccount: {
        code: '001',
        texto: 'Ingresar datos de la Cuenta 1.',
      },
      banViewCert: false,
      banViewCert2: false,
      alertDataSend: true,
      alertTipoAmbiente: true,
      alertTipoAmbiente2: true,
      msgTipoAmbiente: 'Al momento usted realizará facturas REALES dentro del sistema del SRI. Verifique los datos de su RUC en el siguiente enlace: ',
      msgTipoAmbiente2: 'Al momento usted realizará facturas REALES dentro del sistema del SRI. Verifique los datos de su RUC en el siguiente enlace: ',
      tabsBilling: 'tab-1',
      e1: 1,
      loadingSaveData: false,
      loadingUploadCertificate: false,
      loadingUploadCertificate2: false,
      validCompanyData: true,
      validCompanyData2: true,
      companydata: {
        contribuyenteEspecial: '',
        obligadoContabilidad: false,
        regimenMicroempresas: false,
        regimenRimpeEmprendedor: false,
        regimenGeneral: false,
        regimenRimpeNegocio: false,
        agenteRetencion: 'NO APLICA',
        ambiente: {
          codigo: 2,
          texto: 'Producción',
        },
        dirEstablecimiento: '',
        certName: '',
        certPassword: '',
        certExpiration: '',
        sendSri: false,
        calificacionArtesanal: '',
      },
      companydata2: {
        contribuyenteEspecial: '',
        obligadoContabilidad: false,
        regimenMicroempresas: false,
        regimenRimpeEmprendedor: false,
        regimenGeneral: false,
        regimenRimpeNegocio: false,
        agenteRetencion: 'NO APLICA',
        ambiente: {
          codigo: 2,
          texto: 'Producción',
        },
        dirEstablecimiento: '',
        certName: '',
        certPassword: '',
        certExpiration: '',
        sendSri: false,
        calificacionArtesanal: '',
      },
      requeridos: [
        value => !!value || 'Requerido',
      ],
      cert: '',
      cert2: '',
      cert_base64: '',
      cert_base642: '',
      fechaCaducidad: '',
      fechaCaducidad2: '',
      show1: false,
      show_pass_cert: false,
      show_pass_cert2: false,
      passCert: '',
      passCert2: '',
      profile: null,
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
    }),
    computed: {
      profileLoaded () {
        return this.$store.state.profile.profileLoaded
      },
      companyDataLoad () {
        return this.$store.state.facturacion.companydata
      },
      companyDataLoad2 () {
        return this.$store.state.facturacion.companydata2
      },
      electronicbillingtables () {
        return this.$store.state.facturacion.electronicbillingtables
      },
    },
    watch: {
      profileLoaded () {
        if (this.profileLoaded) this.profile = this.profileLoaded
        if (this.profile) {
          if (!this.profile.rucEstablecimieto || !this.profile.razonSocial) {
            this.snackbar = {
              show: true,
              color: 'orange',
              text: 'Para continuar debe completar sus datos de PERFIL.',
            }
            setTimeout(() => {
              this.$router.push('/pages/user')
            }, 3000)
            return
          }
          if (this.profile.rucEstablecimieto === '' || this.profile.rucEstablecimieto === null || this.profile.razonSocial === '' || this.profile.razonSocial === null) {
            this.$router.push('/pages/user')
          }
          if (this.profile.rucEstablecimieto) {
            if (this.selectAccount.code !== '002') {
              this.selectAccount = {
                code: '001',
                texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
              }
            }
            this.acoountsLst = [
              {
                code: '001',
                texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
              },
              {
                code: '002',
                texto: 'Ingresar datos de la Cuenta 2.',
              },
            ]
            if (this.profile.rucEstablecimieto2) {
              this.acoountsLst = [
                {
                  code: '001',
                  texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
                },
                {
                  code: '002',
                  texto: this.profile.rucEstablecimieto2 !== '' ? this.profile.rucEstablecimieto2 + ' - ' + this.profile.razonSocial2 : 'Ingresar datos de la Cuenta 2.',
                },
              ]
            }
          }
        }
      },
      companyDataLoad () {
        this.companydata = this.companyDataLoad
        if (!this.companydata) {
          this.companydata = {
            contribuyenteEspecial: '',
            obligadoContabilidad: false,
            regimenMicroempresas: false,
            regimenRimpeEmprendedor: false,
            regimenGeneral: false,
            regimenRimpeNegocio: false,
            agenteRetencion: 'NO APLICA',
            ambiente: {
              codigo: 2,
              texto: 'Producción',
            },
            dirEstablecimiento: '',
            certName: '',
            certPassword: '',
            sendSri: false,
            calificacionArtesanal: '',
          }
        } else {
          this.banViewCert = true
        }
      },
      companyDataLoad2 () {
        this.companydata2 = this.companyDataLoad2
        if (!this.companydata2) {
          this.companydata2 = {
            contribuyenteEspecial: '',
            obligadoContabilidad: false,
            regimenMicroempresas: false,
            regimenRimpeEmprendedor: false,
            regimenGeneral: false,
            regimenRimpeNegocio: false,
            agenteRetencion: 'NO APLICA',
            ambiente: {
              codigo: 2,
              texto: 'Producción',
            },
            dirEstablecimiento: '',
            certName: '',
            certPassword: '',
            sendSri: false,
            calificacionArtesanal: '',
          }
        } else {
          this.banViewCert2 = true
        }
      },
    },
    mounted () {
      this.alertDataSend = true
      this.profile = this.$store.state.profile.profileLoaded
      if (this.profile) {
        if (!this.profile.rucEstablecimieto || !this.profile.razonSocial) {
          this.snackbar = {
            show: true,
            color: 'orange',
            text: 'Para continuar debe completar sus datos de PERFIL.',
          }
          setTimeout(() => {
            this.$router.push('/pages/user')
          }, 3000)
          return
        }
        if (this.profile.rucEstablecimieto === '' || this.profile.rucEstablecimieto === null || this.profile.razonSocial === '' || this.profile.razonSocial === null) {
          this.snackbar = {
            show: true,
            color: 'orange',
            text: 'Para continuar debe completar sus datos de PERFIL.',
          }
          setTimeout(() => {
            this.$router.push('/pages/user')
          }, 3000)
        }
        if (this.profile.rucEstablecimieto) {
          this.selectAccount = {
            code: '001',
            texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
          }
          this.acoountsLst = [
            {
              code: '001',
              texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
            },
            {
              code: '002',
              texto: 'Ingresar datos de la Cuenta 2.',
            },
          ]
          if (this.profile.rucEstablecimieto2) {
            this.acoountsLst = [
              {
                code: '001',
                texto: this.profile.rucEstablecimieto !== '' ? this.profile.rucEstablecimieto + ' - ' + this.profile.razonSocial : 'Ingresar datos de la Cuenta 1.',
              },
              {
                code: '002',
                texto: this.profile.rucEstablecimieto2 !== '' ? this.profile.rucEstablecimieto2 + ' - ' + this.profile.razonSocial2 : 'Ingresar datos de la Cuenta 2.',
              },
            ]
          }
        }
      }
      this.electronicbillingtables = this.$store.state.facturacion.electronicbillingtables
      this.companydata = this.$store.state.facturacion.companydata
      if (!this.companydata) {
        this.companydata = {
          contribuyenteEspecial: '',
          obligadoContabilidad: false,
          regimenMicroempresas: false,
          regimenRimpeEmprendedor: false,
          regimenGeneral: false,
          regimenRimpeNegocio: false,
          agenteRetencion: 'NO APLICA',
          ambiente: {
            codigo: 2,
            texto: 'Producción',
          },
          dirEstablecimiento: '',
          certName: '',
          certPassword: '',
          sendSri: false,
          calificacionArtesanal: '',
        }
      } else {
        this.banViewCert = true
      }
      this.companydata2 = this.$store.state.facturacion.companydata2
      if (!this.companydata2) {
        this.companydata2 = {
          contribuyenteEspecial: '',
          obligadoContabilidad: false,
          regimenMicroempresas: false,
          regimenRimpeEmprendedor: false,
          regimenGeneral: false,
          regimenRimpeNegocio: false,
          agenteRetencion: 'NO APLICA',
          ambiente: {
            codigo: 2,
            texto: 'Producción',
          },
          dirEstablecimiento: '',
          certName: '',
          certPassword: '',
          sendSri: false,
          calificacionArtesanal: '',
        }
      } else {
        this.banViewCert2 = true
      }
    },
    methods: {
      keyUpArtesanal () {
        this.companydata.calificacionArtesanal = this.companydata.calificacionArtesanal.trim()
      },
      keyUpArtesanal2 () {
        this.companydata2.calificacionArtesanal = this.companydata2.calificacionArtesanal.trim()
      },
      changeRegimenGeneral () {
        if (this.companydata.regimenGeneral) {
          this.companydata.regimenRimpeNegocio = false
          this.companydata.regimenRimpeEmprendedor = false
          this.companydata.regimenMicroempresas = false
        }
      },
      changeRegimenMicroEmpresa () {
        if (this.companydata.regimenMicroempresas) {
          this.companydata.regimenRimpeNegocio = false
          this.companydata.regimenRimpeEmprendedor = false
          this.companydata.regimenGeneral = false
        }
      },
      changeRegimenRimpeEmprendedor () {
        if (this.companydata.regimenRimpeEmprendedor) {
          this.companydata.regimenRimpeNegocio = false
          this.companydata.regimenMicroempresas = false
          this.companydata.regimenGeneral = false
        }
      },
      changeRegimenNegocio () {
        if (this.companydata.regimenRimpeNegocio) {
          this.companydata.regimenRimpeEmprendedor = false
          this.companydata.regimenMicroempresas = false
          this.companydata.regimenGeneral = false
        }
      },
      changeRegimenGeneral2 () {
        if (this.companydata.regimenGeneral2) {
          this.companydata.regimenRimpeNegocio2 = false
          this.companydata.regimenRimpeEmprendedor2 = false
          this.companydata.regimenMicroempresas2 = false
        }
      },
      changeRegimenMicroEmpresa2 () {
        if (this.companydata.regimenMicroempresas2) {
          this.companydata.regimenRimpeNegocio2 = false
          this.companydata.regimenRimpeEmprendedor2 = false
          this.companydata.regimenGeneral2 = false
        }
      },
      changeRegimenRimpeEmprendedor2 () {
        if (this.companydata.regimenRimpeEmprendedor2) {
          this.companydata.regimenRimpeNegocio2 = false
          this.companydata.regimenMicroempresas2 = false
          this.companydata.regimenGeneral2 = false
        }
      },
      changeRegimenNegocio2 () {
        if (this.companydata.regimenRimpeNegocio2) {
          this.companydata.regimenRimpeEmprendedor2 = false
          this.companydata.regimenMicroempresas2 = false
          this.companydata.regimenGeneral2 = false
        }
      },
      changeAmbiente () {
        this.alertTipoAmbiente = true
        if (this.companydata.ambiente.codigo.toString() === '2') {
          this.msgTipoAmbiente = 'Al momento usted realizará facturas REALES dentro del sistema del SRI. El TIPO DE AMBIENTE identifica si realizará facturas de pruebas (Pruebas) o facturas reales (Producción).'
        } else {
          this.msgTipoAmbiente = 'Al momento usted realizará facturas de PRUEBA dentro del sistema del SRI. El TIPO DE AMBIENTE identifica si realizará facturas de pruebas (Pruebas) o facturas reales (Producción).'
        }
      },
      changeAmbiente2 () {
        this.alertTipoAmbiente2 = true
        if (this.companydata2.ambiente.codigo.toString() === '2') {
          this.msgTipoAmbiente2 = 'Al momento usted realizará facturas REALES dentro del sistema del SRI. El TIPO DE AMBIENTE identifica si realizará facturas de pruebas (Pruebas) o facturas reales (Producción).'
        } else {
          this.msgTipoAmbiente2 = 'Al momento usted realizará facturas de PRUEBA dentro del sistema del SRI. El TIPO DE AMBIENTE identifica si realizará facturas de pruebas (Pruebas) o facturas reales (Producción).'
        }
      },
      validCertData () {
        this.cert = ''
      },
      subir_cert () {
        try {
          var reader = new FileReader()
          const extension = this.cert.name.toString().split('.')[1]
          if (!this.passCert || this.passCert === '') {
            this.snackbar = {
              show: true,
              color: 'orange',
              text: 'Para continuar agregue la contraseña de su certificado.',
            }
            setTimeout(() => {
              this.cert = ''
            }, 500)
            return
          }
          if (extension !== 'p12') {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'La extensión del archivo debe ser p12.',
            }
            setTimeout(() => {
              this.cert = ''
            }, 500)
            return
          }
          reader.readAsDataURL(this.cert)
          reader.onloadend = () => {
            this.cert_base64 = reader.result.split(',')[1]
            var p12Der = forge.util.decode64(this.cert_base64)
            var p12Asn1 = forge.asn1.fromDer(p12Der)
            try {
              var p12 = forge.pkcs12.pkcs12FromAsn1(p12Asn1, this.passCert)
              this.snackbar = {
                show: true,
                color: 'green',
                text: 'Certificado y contraseña correctos.',
              }
              this.banViewCert2 = false
              const date = new Date(p12.safeContents[1].safeBags[0].cert.validity.notAfter)
              this.fechaCaducidad = moment(date).format('YYYY-MM-DD')
              this.companydata.certExpiration = this.fechaCaducidad
            } catch (e) {
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'La contraseña ingresada es incorrecta.',
              }
              this.banViewCert = true
              setTimeout(() => {
                this.cert = ''
              }, 500)
            }
          }
        } catch (e) {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Error al momento de leer el archivo.',
          }
        }
      },
      validCertData2 () {
        this.cert2 = ''
      },
      subir_cert2 () {
        try {
          var reader = new FileReader()
          const extension = this.cert2.name.toString().split('.')[1]
          if (!this.passCert2 || this.passCert2 === '') {
            this.snackbar = {
              show: true,
              color: 'orange',
              text: 'Para continuar agregue la contraseña de su certificado.',
            }
            setTimeout(() => {
              this.cert = ''
            }, 500)
            return
          }
          if (extension !== 'p12') {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'La extensión del archivo debe ser p12.',
            }
            setTimeout(() => {
              this.cert2 = ''
            }, 500)
            return
          }
          reader.readAsDataURL(this.cert2)
          reader.onloadend = () => {
            this.cert_base642 = reader.result.split(',')[1]
            var p12Der = forge.util.decode64(this.cert_base642)
            var p12Asn1 = forge.asn1.fromDer(p12Der)
            try {
              var p12 = forge.pkcs12.pkcs12FromAsn1(p12Asn1, this.passCert2)
              this.snackbar = {
                show: true,
                color: 'green',
                text: 'Certificado y contraseña correctos.',
              }
              const date = new Date(p12.safeContents[1].safeBags[0].cert.validity.notAfter)
              this.fechaCaducidad2 = moment(date).format('YYYY-MM-DD')
              this.companydata2.certExpiration = this.fechaCaducidad2
              this.banViewCert2 = false
            } catch (e) {
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'La contraseña ingresada es incorrecta.',
              }
              this.banViewCert2 = true
              setTimeout(() => {
                this.cert = ''
              }, 500)
            }
          }
        } catch (e) {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Error al momento de leer el archivo.',
          }
        }
      },
      nextEventProcess () {
        // this.loadingSaveData = true
        this.e1 = 2
      },
      async createCertificate () {
        if (this.$refs.formCompanyData.validate()) {
          if (!this.$store.state.facturacion.tokenAdmin) {
            await this.$store.dispatch('facturacion/adminLoginFac')
          }
          const data = {
            ruc: this.profile.rucEstablecimieto,
            token: this.$store.state.facturacion.tokenAdmin,
            certificado: this.cert_base64,
            password: this.passCert.trim(),
          }

          this.loadingUploadCertificate = true
          this.$store.dispatch('facturacion/createCertificado', data).then(res => {
            if (res) {
              this.loadingUploadCertificate = false
              this.snackbar = {
                show: true,
                color: 'green',
                text: 'Certificado Agregado de Manera Correcta',
              }

              this.companydata.passCert = this.passCert.trim()
              this.companydata.nameCert = this.cert.name
              this.companydata.certExpiration = this.fechaCaducidad
              this.companydata.sendSri = true

              this.nextEventCompany()
            } else {
              this.loadingUploadCertificate = false
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Error al momento de realizar el proceso.',
              }
            }
          }, reject => {
            this.loadingUploadCertificate = false
            if (reject.response.data.message) {
              this.snackbar = {
                show: true,
                color: 'red',
                text: reject.response.data.message,
              }
              return
            }
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Error al momento de realizar el proceso.',
            }
          })
        }
      },
      async createCertificate2 () {
        if (this.$refs.formCompanyData2.validate()) {
          if (!this.$store.state.facturacion.tokenAdmin) {
            await this.$store.dispatch('facturacion/adminLoginFac')
          }
          const data = {
            ruc: this.profile.rucEstablecimieto2,
            token: this.$store.state.facturacion.tokenAdmin,
            certificado: this.cert_base642,
            password: this.passCert2.trim(),
          }

          this.loadingUploadCertificate2 = true
          this.$store.dispatch('facturacion/createCertificado', data).then(res => {
            if (res) {
              this.loadingUploadCertificate2 = false
              this.snackbar = {
                show: true,
                color: 'green',
                text: 'Certificado Agregado de Manera Correcta',
              }

              this.companydata2.passCert = this.passCert2.trim()
              this.companydata2.nameCert = this.cert2.name
              this.companydata2.certExpiration = this.fechaCaducidad2
              this.companydata2.sendSri = true

              this.nextEventCompany2()
            } else {
              this.loadingUploadCertificate = false
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Error al momento de realizar el proceso.',
              }
            }
          }, reject => {
            this.loadingUploadCertificate2 = false
            if (reject.response.data.message) {
              this.snackbar = {
                show: true,
                color: 'red',
                text: reject.response.data.message,
              }
              return
            }
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Error al momento de realizar el proceso.',
            }
          })
        }
      },
      async nextEventCompany () {
        if (this.$refs.formCompanyData.validate()) {
          this.loadingSaveData = true

          this.$store.dispatch('facturacion/addCompanyData', this.companydata).then(async resolve => {
            this.snackbar = {
              show: true,
              color: 'green',
              text: 'Datos guardados correctamente',
            }
            // this.e1 = 3
            this.loadingSaveData = false
            // this.tabsBilling = 'tab-1'
          }, reject => {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Error al guardar los datos',
            }
            this.loadingSaveData = false
          })
        }
      },
      async nextEventCompany2 () {
        if (this.$refs.formCompanyData2.validate()) {
          this.loadingSaveData = true

          this.$store.dispatch('facturacion/addCompanyData2', this.companydata2).then(async resolve => {
            this.snackbar = {
              show: true,
              color: 'green',
              text: 'Datos guardados correctamente',
            }
            // this.e1 = 3
            this.loadingSaveData = false
            // this.tabsBilling = 'tab-1'
          }, reject => {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Error al guardar los datos',
            }
            this.loadingSaveData = false
          })
        }
      },
    },
  }
</script>
<style lang="css">
  .v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    height: auto;
    color: black;
  }
</style>
